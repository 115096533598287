import { PureComponent } from 'react'
import styled from '@emotion/styled'
import { Image, Box, ProductItemsModal, GroupedListRow, RoundedLabel, Button, Icons } from 'stylewhere/components'
import { DecodedItem, Products, TmrProduct, TmrStatus, IdentifierType, ProductInfoField } from 'stylewhere/api'
import { isNumber } from 'stylewhere/utils'
import { T, __ } from 'stylewhere/i18n'
import { OperationConfig, ShippingOperationConfig, AppStore } from 'stylewhere/shared'

interface Props {
  operation: OperationConfig | ShippingOperationConfig
  product: TmrProduct
  detected?: number
  expected?: number
  unexpected?: number
  hideCounter?: boolean
  noShadow?: boolean
  flex?: boolean
  status?: TmrStatus
  decodedItems: DecodedItem[]
  onRemoveItem?: (item: DecodedItem) => void
  onActionItem?: (item: DecodedItem) => void
  removeModeEnabled?: boolean
  hideIdentifiersByType?: IdentifierType[]
  hideIdentifiersZones?: boolean
  overrideProductInfoFields?: ProductInfoField[]
}

interface State {
  modalVisible: boolean
}

export class GroupedProductRow extends PureComponent<Props, State> {
  static Skeleton = styled(Box)``

  state: State = {
    modalVisible: false,
  }

  renderCounterError = (title: 'WARNING' | 'ERROR', counter) => {
    if (counter === 0) return null
    return (
      <Box
        center
        pv={5}
        mt={5}
        ph={5}
        style={{
          fontWeight: 'bold',
          width: '100%',
          backgroundColor: title === 'ERROR' ? '#FF8474' : '#FFEA67',
          borderRadius: 5,
        }}
      >
        {title} ({counter})
      </Box>
    )
  }

  getProductCode = () => {
    const { product } = this.props
    if (
      product.attributes &&
      product.attributes.barcode &&
      product.attributes.barcode.value &&
      product.attributes.barcode.value !== ''
    )
      return product.attributes.barcode.value
    return product.code
  }

  getSubClass = () => {
    const { product } = this.props
    let subclass
    if (product.attributes && product.attributes.subclass) {
      if (
        product.attributes.subclass.description &&
        product.attributes.subclass.description !== null &&
        product.attributes.subclass.description !== ''
      ) {
        subclass = product.attributes.subclass.description
      } else if (
        product.attributes.subclass.value &&
        product.attributes.subclass.value !== null &&
        product.attributes.subclass.value !== ''
      ) {
        subclass = product.attributes.subclass.value
      }
    }
    return subclass
  }

  render() {
    const {
      product,
      detected,
      operation,
      expected,
      unexpected,
      decodedItems,
      hideCounter,
      noShadow,
      flex,
      status,
      onRemoveItem,
      removeModeEnabled,
    } = this.props
    const read = detected ?? 0
    const hasChecklist = 'hasChecklist' in operation && operation.hasChecklist
    const counterValue = expected ? `${read}/${expected}` : `${read}${hasChecklist ? '/0' : ''}`
    const counterErrorItems = decodedItems.filter((item) => item.item?.status === 'error').length
    const counterWarningItems = decodedItems.filter((item) => item.item?.status === 'warning').length
    const subclass = this.getSubClass()
    return (
      <>
        {this.state.modalVisible && (
          <ProductItemsModal
            onClose={() => this.setState({ modalVisible: false })}
            isOpen={this.state.modalVisible}
            product={product}
            decodedItems={decodedItems}
            onRemoveItem={onRemoveItem}
            operation={operation}
          />
        )}
        <GroupedListRow
          ph={10}
          noShadow={noShadow}
          flex={flex}
          onClick={detected ? () => this.setState({ modalVisible: true }) : undefined}
          clickable={detected !== undefined && detected > 0}
        >
          {product.code && AppStore.getShowProductImage() && (
            <Box center>
              <Image width={115} height={120} src={Products.getImageUrl(product)} />
            </Box>
          )}
          <Box flex ph={10} style={{ alignSelf: 'center' }}>
            <Box mb={5}>
              <ProductCode>{this.getProductCode()}</ProductCode>
              {product.description && <ProductDescription>{product.description}</ProductDescription>}
            </Box>

            <Box row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
              {product.attributes.variantType?.value && (
                <RoundedLabel
                  label={__(T.misc.typology)}
                  value={product.attributes?.variantType?.value}
                  style={{ backgroundColor: '#70cdee' }}
                />
              )}
              <RoundedLabel
                label={__(T.misc.model)}
                value={product.attributes?.modellocodice?.description ?? product.attributes?.modellocodice?.value}
              />
              <RoundedLabel label={__(T.misc.size)} value={product.size?.description ?? product.size?.value} />
              <RoundedLabel label={__(T.misc.color)} value={product.color?.description ?? product.color?.value} />
              {subclass && <RoundedLabel label={__(T.custom.subclass)} value={subclass} />}
            </Box>
          </Box>
          <Box center>
            {!hideCounter && (isNumber(detected) || isNumber(expected) || isNumber(unexpected)) && (
              <SmallCounter
                ph={20}
                detected={detected}
                expected={expected}
                unexpected={unexpected}
                noChecklist={!hasChecklist}
                status={status}
              >
                {counterValue}
              </SmallCounter>
            )}
            {this.renderCounterError('ERROR', counterErrorItems)}
            {this.renderCounterError('WARNING', counterWarningItems)}
          </Box>
        </GroupedListRow>
        {removeModeEnabled && (
          <TrashContainer>
            <Button
              variant="error"
              circle
              onClick={() => {
                if (onRemoveItem && decodedItems) {
                  decodedItems.forEach(async (item) => {
                    onRemoveItem(item)
                  })
                }
              }}
            >
              <Icons.Trash />
            </Button>
          </TrashContainer>
        )}
      </>
    )
  }
}

const ProductDescription = styled.label`
  font-size: 15px;
  word-break: break-word;
`
const ProductCode = styled.label`
  font-weight: bold;
  font-size: 20pt;
  word-break: break-word;
`

const SmallCounter = styled(Box)<{
  detected?: number
  expected?: number
  unexpected?: number
  noChecklist?: boolean
  status?: TmrStatus
}>`
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  min-width: 100px;
  align-self: center;
  background-color: ${({ detected = 0, expected = 0, unexpected = 0, noChecklist = false, status }) => {
    if (noChecklist) return '#F0F0F0'
    switch (status) {
      case 'error':
        return '#FF8474'
      case 'warning':
        return '#FFEA67'
      case 'ignored':
        return '#DAE6ED'
      default:
        if (detected + unexpected > expected) return '#F2B57D'
        if (noChecklist) return '#75EBA8'
        if (unexpected > 0) return '#F2B57D'
        if (detected < expected) return '#EDEDED'
        if (detected === expected) return '#75EBA8'
        return '#F0F0F0'
    }
  }};
  border-radius: 10px;
`

const TrashContainer = styled(Box)`
  padding: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`
