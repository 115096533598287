import { merge as _merge } from 'lodash'
import en from 'core/translations/en'

export default _merge(en, {
  titles: {
    outbound_bthp: 'Outbound BTHP Certificate',
  },
  simple_parcel_list: {
    to_read: 'To Read',
    ok: 'Ok',
    force: 'Force',
    force_parcel: 'Force parcel {{code}}',
    force_parcel_desc: 'Insert user PIN to force parcel',
    forced: 'Forced',
    unexpected: 'Unexpected',
    remove: 'Remove',
    invalid_pin: 'User PIN invalid',
    loading: 'Loading parcels list',
  },
  create_parcel_gate: {
    width: 'Width',
    height: 'Height',
    depth: 'Depth',
    tare: 'Tare',
    description: 'Description',
  },
  misc: {
    sorted_quantity: 'Sorted qty',
    sorted_date: 'Sorted date',
  },
  error: {
    sorting_already_read: 'Item with serial number {{serial}} already ventilated on customer {{group}}',
    on_destination: ' on destination {{destination}}',
    item_not_sortable: 'Item with serial {{serial}} is not sortable',
    nfc_already_associated: 'NFC already associated. Disassociate it and repeat the operation',
    tags_mismatch_error: 'No association found between the tags',
    sorting_not_in_zone: 'Item with serial {{serial}} not sortable. Item in zone {{zone}}',
  },
  serverError: {
    WRONG_ITEM_SUPPLIER: 'Some read articles cannot be taken over by the supplier.',
    QUANTITY_MISMATCH_ERROR: 'Quantities entered do not correspond to the quantities detected.',
    ZONE_MISMATCH_ERROR: 'Zone entered does not correspond to the zone detected.',
  },
  custom: {
    barcode: 'Barcode BC',
    barcodeBCTrim: 'Barcode BC trim',
    barcodeJDE: 'Barcode JDE',
    barcodeROQ: 'Barcode ROQ',
    barcodeROQTrim: 'Barcode ROQ trim',
    class: 'Class',
    department: 'Department',
    dimensionSystem: 'Custom class',
    ean: 'EAN',
    eanIdPadre: 'Father EAN',
    itemId: 'Item Id',
    stagioneROQ: 'ROQ season',
    subclass: 'Model Type',
    subdepartment: 'Subdepartment',
    variantType: 'Typology ',
    rfidEnabled: 'RFID enabled',
    stato: 'Status',
    ricondizionamentoFornitore: 'Recondition supplier',
    codiceShelf: 'Stock Compartment',
    montante: 'Section',
    ripiano: 'Bin',
    scaffale: 'Bay',
    ASSOCIATED: 'Associated',
    NOT_ASSOCIATED: 'Not associated',
    REPLACED_ASSOCIATION: 'Replaced',
    identifiers: 'Identifiers',
    force_parcel: 'Force parcel',
    force_parcel_message: 'Are you sure you want to force this parcel?',
    pesa_collo: 'Weight neck',
    peso_kg: 'Weight (kg)',
    wrong_packaging_code: 'Wrong packing code',
    packaging_confirmed_no_weight: 'You are confirming a package without weight, do you want to continue?',
    attention: 'Attention',
    continue: 'Continue',
    model: 'Modello',
    sizeCode: 'Size code',
    sizeDescription: 'Size description',
    serial: 'Serial',
    identifier: 'Serial',
    product: 'Barcode BC',
    shift: 'Shift',
    destination: 'Destination',
    regDate: 'Date',
    listPosition: 'List position',
    list: 'List',
    customerName: 'Customer Name',
    regTime: 'Time',
    event: 'Event',
    customer: 'Customer',
    isCarryover: 'Carryover',
    carryoverValue: 'Carryover',
    madeIn: 'Made in',
    collectionCode: 'Collection',
    collectionDescription: 'Collection Description',
    collectionYear: 'Year',
    fatherProductValue: 'Father Product',
    genderValue: 'Gender',
    genderValueDescription: 'Gender Description',
    line: 'Brandline',
    lineDescription: 'Line Description',
    marchioDescription: 'Marchio Description',
    marchioValue: 'Marchio',
    merchandiseClassCode: 'Category',
    merchandiseClassDescription: 'Category Description',
    merchandiseSubclassCode: 'Sub-Category',
    merchandiseSubclassDescription: 'Sub-Category Description',
    modelPartColor: 'Model Part Color',
    modelPartColorSizeValue: 'Model Part Color Size',
    isPermanent: 'Permanent',
    permanentValue: 'Permanent',
    isSeasonal: 'Seasonal',
    seasonalValue: 'Seasonal',
    sleeveTypeValue: 'Sleeve Type',
  },
})
